// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Markets
\* ------------------------------------------------------------------------ */

.markets {
    /* stylelint-disable selector-no-qualifying-type, selector-no-type */

    & {
        list-style-type: none;
        padding-left: remify(45, 22) !important;
    }

    li {
        line-height: remify(40, 22);
        padding-bottom: 5px;
        position: relative;
    }

    li::before {
        background-color: $foreground;
        border-radius: remify(20, 20);
        color: $background;
        content: "\f02b";
        font-family: "FontAwesome";
        font-size: remify(20, 22);
        height: remify(40, 20);
        left: remify(-45, 20);
        line-height: remify(40, 20);
        position: absolute;
        text-align: center;
        width: remify(40, 20);
    }

    & .-aerospace::before {
        content: "\f1d9";
    }

    & .-automotive::before {
        content: "\f1b9";
    }

    & .-electronics::before {
        content: "\f0eb";
    }

    & .-medical::before {
        content: "\f0f8";
    }

    & .-micromachining::before {
        content: "\f085";
    }

    & .-semiconductors::before {
        content: "\f26c";
    }
}
