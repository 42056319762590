// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }

    @media screen and (max-width: $xs-break - (1 / 16)) {
        text-align: center;
    }
}

.footer_inner {
    & {
        background: $page_background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify($content-padding ($content-padding * 0.625));
    }
}

.footer_link {
    & {
        color: inherit;
    }

    &:focus,
    &:hover {
        color: inherit;
        text-decoration: underline;
    }
}

.footer_title {
    & {
        font-size: remify(22, 16);
    }
}

.footer_text {
    & {
        color: $light;
        font-size: remify(18, 16);
    }

    &.-alt {
        color: lighten(desaturate($page_background, 12.6403), 42.549);
    }
}

.footer_user-content {
    /* stylelint-disable selector-no-type */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: remify(22, 16);
    }

    p,
    ol,
    ul,
    table {
        font-size: remify(18, 16);
    }

    /* stylelint-enable selector-no-type */
}

.footer_iframe_container {
    & {
        margin: remify(0 0 24, 16);
    }
}

/* fullbleed variant */

.footer-block.-fullbleed {
    & {
        background: $page_background;
        padding: remify($content-padding ($content-padding * 0.625));
    }

    > .footer_inner {
        background: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}
