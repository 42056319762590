// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title {
    & {
        color: $primary;
        display: block;
        font-family: $heading-font;
        font-size: remify(30, 16);
        font-weight: 700;
        line-height: remify(32, 30);
        margin: remify(0 0 32, 30);
    }
}

/* Tablet variant */

@media screen and (min-width: $xs-break) {
    .title {
        & {
            font-size: remify(36, 16);
        }
    }
}
