// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Iframe
\* ------------------------------------------------------------------------ */

.iframe_container {
    & {
        height: 0;
        padding-bottom: 56.25%; // 16:9 default
        position: relative;
        width: 100%;
    }

    > .iframe {
        border: 0;
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
}
