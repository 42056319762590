// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * CTA
\* ------------------------------------------------------------------------ */

.cta_title {
    & {
        font-size: remify(26, 16);
    }
}
