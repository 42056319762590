// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Vendor

@import "../../vendor/_swiper";

// Custom

.swiper-slide {
    & {
        flex-grow: 1;
        height: auto;
        margin: 0;
        overflow: hidden;
    }
}

.swiper-slide-inner {
    & {
        margin: 0 auto;
        max-width: remify($site-width + (($content-padding * 0.625) * 2));
        position: relative;
    }
}

.swiper-picture {
    & {
        display: block;
        height: 0;
        padding-bottom: 50%;
        position: relative;
        width: 100%;
        z-index: 0;
    }
}

.swiper-image {
    & {
        /* stylelint-disable property-no-vendor-prefix */
        -webkit-mask-image: -webkit-gradient(linear, left 80%, left bottom, from(transparentize($dark, 0)), to(transparentize($dark, 1)));
        /* stylelint-enable property-no-vendor-prefix */
        mask-image: gradient(linear, left 80%, left bottom, from(transparentize($dark, 0)), to(transparentize($dark, 1)));
        position: absolute;
        right: 0;
        top: 0;
        width: 90%;
    }
}

.swiper-caption {
    & {
        padding: remify(($content-padding * 0.85) ($content-padding * 0.625));
        position: relative;
        z-index: 1;
    }
}

.swiper-user-content {
    @media screen and (max-width: $xs-break - (1 / 16)) {
        /* stylelint-disable selector-no-type */
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-align: center;
        }
        /* stylelint-enable selector-no-type */
    }
}

.swiper-button {
    & {
        background: none;
        border: 0;
        color: $primary;
        display: block;
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        top: 0;
        transition: opacity 0.15s;
        width: remify(55, 16);
    }

    &.-prev {
        background: none;
        left: 0;
    }

    .swiper-container-rtl &.-prev {
        background: none;
        right: 0;
    }

    &.-next {
        background: none;
        right: 0;
    }

    .swiper-container-rtl &.-next {
        background: none;
        left: 0;
    }

    .swiper-container:hover & {
        opacity: 0.5;
    }

    .swiper-container:hover &:hover,
    .swiper-container:hover &:focus {
        opacity: 1;
    }

    .swiper-button-icon,
    .swiper-button-icon {
        font-size: remify(48, 16);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    & {
        bottom: auto;
        top: 0;
    }
}

.swiper-pagination-bullet {
    & {
        background: transparentize($dark, 0.5);
        height: remify(10, 16);
        opacity: 1;
        transition: background 0.15s;
        width: remify(10, 16);
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    & {
        margin: remify(20 5, 16);
    }
}

.swiper-pagination-bullet-active {
    & {
        background: $primary;
    }
}
