// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Text
\* ------------------------------------------------------------------------ */

.text {
    & {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(18, 16);
        font-weight: 400;
        line-height: remify(24, 18);
        margin: remify(0 0 24, 18);
    }
}

/* Tablet variant */

@media screen and (min-width: $xs-break) {
    .text {
        & {
            font-size: remify(22, 16);
        }
    }
}

/* label variant */

.text.-label {
    & {
        cursor: pointer;
    }
}
