// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #121131;
$primary_alt: #1B1A4A;

$secondary: #223F49;
$secondary_alt: #3B6C7D;

$accent: #B7232F;
$accent_alt: #D12836;

$light: #FFFFFF;
$light_alt: #D8D8D8;

$dark: #000000;
$dark_alt: #4A4A4A;

$foreground: #4A4A4A;
$foreground_alt: #000000;

$background: #FFFFFF;
$background_alt: #D8D8D8;

$page_background: #121131;
$page_background_alt: #1B1A4A;

$warning: #9F0000;
$warning_alt: #F83636;

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$heading-font: "eurostile", "San Francisco", "Open Sans", "Helvetica", "Arial", sans-serif;
$body-font: "San Francisco", "Open Sans", "Helvetica", "Arial", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1280;
$column-gap: 30;
$content-padding: 40;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1440em / 16;
$xxl-break: 1366em / 16;
$xl-break: 1280em / 16;
$l-break: 1024em / 16;
$m-break: 960em / 16;
$s-break: 853em / 16;
$xs-break: 768em / 16;
$xxs-break: 640em / 16;
$xxxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    header: 6,
    navigation: 5,
    hero: 4,
    content: 2,
    cta: 3,
    footer: 1,
);
