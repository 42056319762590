// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: $accent;
        border: 0;
        box-shadow: remify(0 2 4 0, 18) transparentize($dark, 0.5);
        color: $light;
        cursor: pointer;
        display: inline-block;
        font-family: $heading-font;
        font-size: remify(18, 16);
        font-style: italic;
        font-weight: 400;
        line-height: remify(22.5, 18);
        margin: remify(0 0 8, 18);
        outline: 0;
        padding: remify(12.5 25, 18);
        position: relative;
        text-decoration: none;
        transition: background 0.15s;
    }

    &::before {
        border-bottom: remify(8.5, 18) solid transparent;
        border-left: remify(13.7, 18) solid $primary;
        border-top: remify(8.5, 18) solid transparent;
        content: "\0020";
        display: block;
        height: 0;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
    }

    &:focus,
    &:hover {
        background: $accent_alt;
    }
}

/* Desktop variant */

@media screen and (min-width: $xs-break) {
    .button {
        font-size: remify(20, 16);
        padding: remify(12.5 25, 20);
    }
}
