// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Custom

.swiper-slide-inner {
    & {
        align-items: center;
        display: flex;
    }
}

.swiper-picture {
    & {
        height: auto;
        order: 2;
        padding-bottom: 0;
        width: 50%;
    }
}

.swiper-image {
    & {
        float: right;
        margin-right: remify(-100, 16);
        /* stylelint-disable property-no-vendor-prefix */
        -webkit-mask-image: none;
        /* stylelint-enable property-no-vendor-prefix */
        mask-image: none;
        position: static;
        right: auto;
        top: auto;
        width: auto;
    }
}

.swiper-caption {
    & {
        order: 1;
        position: relative;
        width: 50%;
        z-index: 1;
    }
}

.swiper-user-content {
    & {
        width: 75%;
    }
}
