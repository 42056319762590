// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    @media screen and (max-width: $xl-break) {
        font-size: 1.25vw;
    }
}

.header_logo {
    & {
        max-width: 100%;
        padding-right: remify(($content-padding * 2));
        width: remify(380, 16);
    }
}
