// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero-block {
    & {
        @include layer("hero");
    }
}

.hero_inner {
    & {
        background: url("../media/background-machine.jpg") center center / cover no-repeat $secondary;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 1.25) $content-padding);
    }
}

/* fullbleed variant */

.hero-block.-fullbleed {
    & {
        background: url("../media/background-machine.jpg") center center / cover no-repeat $secondary;
        padding: remify(($content-padding * 1.25) $content-padding);
    }

    > .hero_inner {
        background: none;
        padding: 0;
    }

    > .hero_inner.-fullbleed {
        max-width: none;
    }
}
