// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }
}

.header_inner {
    & {
        background: $background;
        box-shadow: remify(0 2 4 0) transparentize($dark, 0.5);
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(0 ($content-padding * 0.625));
    }
}

.header_logo {
    & {
        max-width: 100%;
        padding: remify(($content-padding * 0.375) 0);
        width: remify(200);
    }
}

.header_link {
    & {
        color: $primary;
    }

    &:focus,
    &:hover {
        color: lighten($primary, 10);
    }
}

.header_title {
    & {
        color: $primary;
        font-size: remify(28, 16);
        font-style: italic;
        font-weight: 400;
        line-height: remify(34, 28);
    }
}

.header_toolbar {
    & {
        background: $background_alt;
        padding: remify(($content-padding * 0.475) ($content-padding * 0.625) ($content-padding * 0.4));
        position: relative;
        text-align: right;
    }

    &::before {
        background: $background_alt;
        bottom: 0;
        content: "\0020";
        display: block;
        height: 100%;
        left: remify(($content-padding * -1));
        position: absolute;
        top: 0;
        transform: skewX(45deg);
        width: 200%;
        width: 100vw;
        z-index: -1;
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        background: $background;
        box-shadow: remify(0 2 4 0) transparentize($dark, 0.5);
        padding: remify(0 ($content-padding * 0.625));
    }

    > .header_inner {
        background: none;
        box-shadow: none;
        padding: 0;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}
