// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * CTA
\* ------------------------------------------------------------------------ */

.cta-block {
    & {
        @include layer("cta");
    }
}

.cta_inner {
    /* stylelint-disable selector-no-qualifying-type, selector-no-type */
    & {
        background: $accent;
        box-shadow: remify(0 2 4 0) transparentize($dark, 0.5);
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.85) ($content-padding * 0.625));
    }

    a {
        & {
            color: $background;
            text-decoration: none;
            transition: color 0.15s;
        }

        &:hover {
            color: $background_alt;
        }
    }
}

.cta_title {
    & {
        color: $light;
        font-style: italic;
        font-weight: 400;
        margin: 0;
        text-align: center;
    }
}

/* fullbleed variant */

.cta-block.-fullbleed {
    & {
        background: $accent;
        box-shadow: remify(0 2 4 0) transparentize($dark, 0.5);
        padding: remify(($content-padding * 0.85) ($content-padding * 0.625));
    }

    > .cta_inner {
        background: none;
        box-shadow: none;
        padding: 0;
    }

    > .cta_inner.-fullbleed {
        max-width: none;
    }
}
