// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu Toggle
\* ------------------------------------------------------------------------ */

.menu-toggle {
    & {
        background: none;
        border: 0;
        color: $accent;
        display: inline-block;
        font-size: remify(40, 16);
        line-height: remify(40, 40);
        padding: remify(10, 40);
    }

    &:focus,
    &:hover {
        color: lighten($accent, 10);
    }
}
